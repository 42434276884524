let UserProfile = (function () {

  let jwt = (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'uat') ? undefined : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWNhMTZlMmRkMTg3ODM4MTQ5NzczY2UiLCJpYXQiOjE1OTAzMDI0MzQsImV4cCI6MzMxMTYzNDQ4MzR9.TNTSwME63Czcvk_KFt58gEkcgjtOd4_IahbAOLVxqhU';
  let loggedIn = (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'uat') ? false : true;

  // let jwt = undefined;
  // let loggedIn = false;

  let getJwt = () => {
    return jwt;
  }

  let setJwt = (token) => {
    jwt = token;
  }

  let setLoggedIn = (login) => {
    loggedIn = login;
  }

  let isLoggedIn = () => {
    return loggedIn && getJwt() !== undefined;
  }

  let businessType = 1;
  let getBusinessType = () => {
    return businessType;
  }

  let setBusinessType = (bType) => {
    businessType = bType;
  }

  return {
    getJwt: getJwt,
    setJwt: setJwt,
    isLoggedIn: isLoggedIn,
    setLoggedIn: setLoggedIn,
    getBusinessType: getBusinessType,
    setBusinessType: setBusinessType
  }
})();

export default UserProfile;