import React from "react";
import { Route, Redirect } from "react-router-dom";
import UserProfile from "./utils/auth";

function PrivateRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={props =>
        UserProfile.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          //bypass login for dev
          //<Component {...props} />
          <Redirect to={{pathname:'/auth/signin', state: {referrer: props.location}}} />
        )
      }
    />
  );
}

export default PrivateRoute;
